<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">
              Resultados
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Resultados
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-tabs mb-4">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'resultados' }"
                    class="nav-link chart-tab active"
                    aria-selected="false"
                  >
                    Provas
                  </router-link>
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'resultados-turma' }"
                    class="nav-link chart-tab"
                    aria-selected="false"
                  >
                    Turmas
                  </router-link>
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'resultados-aluno-lista' }"
                    class="nav-link chart-tab"
                    aria-selected="false"
                  >
                    Alunos
                  </router-link>
                </li>
              </ul>
            </div>

            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="ano">Ano</label>
                    <select
                      id="ano"
                      class="form-control"
                      :disabled="anos.length <= 0"
                      @change="onChangeAno($event)"
                    >
                      <option v-for="ano in anos" :key="ano.id" :value="ano.id">
                        {{ ano.titulo }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="ano">Componente Curricular</label>
                    <select
                      id="disciplina"
                      class="form-control"
                      @change="onChangeDisciplina($event)"
                    >
                      <option
                        v-for="item in disciplinas"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.text }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <button
                      class="btn btn-classic"
                      @click.prevent="clean"
                      style="margin-top:27px"
                    >
                      Limpar
                    </button>
                  </div>
                </div>

                <!--<div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button class="btn btn-classic">
                        <i class="fa fa-print" /> IMPRIMIR
                      </button>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="block__classic table table__results table-striped table-borderless table-hover text-center"
              >
                <thead>
                  <tr>
                    <th class="text-left pl-4" scope="col">Componente Curricular</th>
                    <th scope="col">Ano</th>
                    <th scope="col">Turma</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Média Turma</th>
                    <th scope="col">Desempenho</th>
                    <th scope="col">Data</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in this.provasFiltradas" :key="item.id">
                    <td
                      class="text-left pl-4"
                      v-html="item.prova.disciplina.text"
                    ></td>
                    <td
                      class="text-center"
                      v-html="item.prova.turma.anoEnsino.titulo"
                    ></td>
                    <td
                      class="text-center"
                      v-html="item.prova.turma.titulo"
                    ></td>
                    <td class="text-left" v-html="`<strong>${item.prova.titulo}</strong>`"></td>
                    <td class="text-center" v-if="item.possuiEstatisticaProva"><strong>{{ calcularMedia(item) }}</strong></td>
                    <td class="text-center" v-else>-</td>
                    <td class="text-left" v-if="item.possuiEstatisticaProva">
                      <div>
                        <b-progress class="mt-2">
                          <b-progress-bar
                            :value="item.qtdQuestoesCorretas * 100"
                            variant="success"
                          ></b-progress-bar>
                          <b-progress-bar
                            :value="item.qtdQuestoesErradas * 100"
                            variant="danger"
                          ></b-progress-bar>
                        </b-progress>
                      </div>
                    </td>
                    <td class="text-center" v-else>-</td>

                    <td class="text-left">
                      {{ item.prova.dataHoraInicio | formatDate }}
                    </td>
                    <td v-if="item.possuiEstatisticaProva">
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="showResult(item.prova.id)"
                      >
                        <i class="far fa-eye" />
                      </button>
                    </td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import moment from "moment";
// import Navigator from "../../../components/resultados/ResultadosNavigator.vue";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    // Navigator,
  },
  data() {
    return {
      showLeftMenu: true,
      provasComEstatistica: [],
      provasSemEstatistica: [],
      provasFiltradas: [],
      anos: [],
      disciplinas: [],
      ano: null,
      disciplina: null,
    };
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadProvas();
    this.loadDisciplinas();
    this.loadAnos();
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
      } else {
        return "-";
      }
    },
  },
  methods: {
    calcularMedia(item){
      return item.media.toFixed(2);
      // return (item.media / item.prova.notaMaxima * 10).toFixed(2);
    },
    showResult(id) {
      this.$router.push("/resultados-alunos/" + id);
    },
    loadAnos() {
      this.$root.$emit("Spinner::show");

      this.$api.get("ano_ensinos").then((response) => {
        this.anos = response.data.filter(res => res.trashAt == null)
        this.ano = this.anos[0] || null;
        this.$root.$emit("Spinner::hide");
        if(this.disciplina && this.ano){
          this.filtrar();
        }
      });
    },
    loadDisciplinas() {
      this.$root.$emit("Spinner::show");

      this.$api.get("disciplinas").then((response) => {
        this.disciplinas = response.data;
        this.disciplina = this.disciplinas[0] || null;
        this.$root.$emit("Spinner::hide");
        if(this.disciplina && this.ano){
          this.filtrar();
        }
      });
    },

    onChangeAno(e) {
      this.ano = this.anos.find(ano => ano.id == e.target.value);
      this.filtrar();
    },

    filtrar(){
      // Para facilitar a experiência é melhor deixar as provas com estatística no topo da página
      this.provasFiltradas = this.provasComEstatistica.concat(this.provasSemEstatistica).filter((data) => {
        return data.prova.turma.anoEnsino.id == this.ano.id && data.prova.disciplina.id == this.disciplina.id;
      });
    },

    onChangeDisciplina(e) {
      this.disciplina = this.disciplinas.find(disciplina => disciplina.id == e.target.value);
      this.filtrar();
    },
    clean() {
      window.location.reload();
    },
    loadProvas() {
      this.$api
        .get("/provas-criadas")
        .then((response) => {
          this.provasSemEstatistica = response.data.filter(prova => !prova.possuiEstatisticaProva).map(prova => {
            return {possuiEstatisticaProva: false, prova: prova}
          })
          this.provasComEstatistica = [];
          response.data.filter(prova => prova.possuiEstatisticaProva)
            .forEach((res) => {
              this.$api.get("estatistica-prova/" + res.id).then((est) => {
                est.data.possuiEstatisticaProva = true;
                this.provasComEstatistica.push(est.data);
                if(this.disciplina && this.ano){
                  this.filtrar();
                }
              });
            })
            .catch((error) => {
              console.log(error);
            });
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style></style>
